<template>
    <v-select v-model="selected" :items="tags" item-value="value" label="Koszt interwencji" clearable chips>
        <template v-slot:selection="{ item }">
            <!--index-->
            <v-chip text-color="white" :color="item.color" small>{{
                item.short
            }}</v-chip>
        </template>
        <template v-slot:item="{ item, attrs, on }">
            <v-list-item v-on="on" v-bind="attrs" #default="{}">
                <!--active-->
                <!-- <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action> -->
                <v-list-item-content>
                    <v-list-item-title>
                        <v-row no-gutters align="center">
                            <span>{{ item.name }}</span>
                            <v-spacer></v-spacer>
                            <v-chip text-color="white" :color="item.color" small>{{
                                item.short
                            }}</v-chip>
                        </v-row>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </template>
    </v-select>
</template>

<script>

import { get } from "vuex-pathify";

export default {
    name: "PaymentsSelect",
    data: () => ({
        selected: null,
        tags: get("payments/items")
    }),
    mounted() {
        console.log("token", this.token)
        this.selected = this.token
    },
    props: {
        token: {
            type: Number,
            default: 0
        },
    },
    watch: {
        selected: {
            handler: function (val) {
                this.$emit('token-changed', val)
            },
            deep: true,
        },
    },
    computed: {
        subtitle() {
            return this.$options.filters.siteFullName(this.site)
        }
    },
    methods: {
    },
};
</script>
<style></style>
