import httpClient from './http.client'
// import SiteMember from "@/store/modules/siteMember"

class DictionaryService {
  get(key) {
    return httpClient.get(`v1/dictionary?key=${key}`).then((res) => res.data);
  }

  getPayments() {
    return this.get('payments').then((res) => res[0]);
  }
}

export let dictionaryService = new DictionaryService();