import { UserTaskType, isClosed } from '@/store/modules/task'
import TaskService from '@/services/task.service'
import UserTask from '@/store/modules/task'
import paidCard from '@/components/PaidCard.vue'

class TaskItem {
    title = null
    color = "teal lighten-2"
    createdAt = new Date()
    description = null
    assignees = []
    actions = []
    constructor(id, event, task) {
        this.id = id
        this.task = task
        this.event = event
    }
    update(item) {
        this.title = item.title
        this.color = item.color
        this.createdAt = item.createdAt
        this.description = item.description
        this.assignees = item.assignees
        this.actions = item.actions
        this.chips = item.chips
    }
    static allItems(context, task) {
        const items = []
        items.push(TaskItem.createStart(context, task))
        for (const index in task.details.history) {
            const item = TaskItem.createStage(context, task, index)
            items.push(item)
        }
        if (task.closed_at != null) {
            items.push(TaskItem.createEnd(context, task))
        }
        return items
    }
    static createStart(context, task) {
        let title
        switch (task.task_type) {
            case UserTaskType.DISPATCHER:
                title = "Rozpoczęcie obsługi"
                break;
            case UserTaskType.PATROL:
                title = "Wysłanie patrolu"
                break;
            case UserTaskType.SERVICE:
                title = "Wysłanie serwisu"
                break;
        }
        const item = new TaskItem(task.id + "-start", context.event, task);
        item.title = task.id + " " + title
        item.color = "green lighten-2"
        item.createdAt = task.created_at
        item.description = task.description
        item.assignees = task.assignees.length == 0 ? [{ user: { username: "Operator" } }] : task.assignees
        const editAction = TaskItem.editDesriptionAction(async () => {
            const res = await context.$dialog.prompt({
                text: 'Komentarz',
                value: task.description,
                title: title,
                rules: [],
                actions: {
                    false: 'Anuluj',
                    true: {
                        color: 'success',
                        text: 'OK',
                    }
                }
            })
            if (res || res.length == 0) {
                task.description = res
                context.addAll([task])
                TaskService.updateTask(this.event, task.id, task).then(() => {
                    context.$dialog.notify.info('Komentarz został zmieniony ' + res)
                })
            }
        })
        const cancelAction = this.cancelPatrolAction(async () => {
            const res = await context.$dialog.prompt({
                text: 'Przyczyna',
                value: null,
                title: title,
                rules: [],
                actions: {
                    false: 'Anuluj',
                    true: {
                        color: 'success',
                        text: 'OK',
                    }
                }
            })
            if (res === false) return
            TaskService.cancelTask(
                this.event.site.id,
                task.id,
                'Odwołany',
                null,
                null
            ).then(context.refresh())
        })
        cancelAction.visible = task.task_type == UserTaskType.PATROL && !UserTask.isFinished(task)

        const closeAction = this.closeTaskAction(async () => {
            const res = await context.$dialog.prompt({
                text: 'Przyczyna',
                value: null,
                title: title,
                rules: [],
                actions: {
                    false: 'Anuluj',
                    true: {
                        color: 'success',
                        text: 'OK',
                    }
                }
            })
            if (res === false) return

            TaskService.closeTask(context.event.site.id, task.id, res)
                .then(context.refresh())
                .catch(error => {
                    console.error(error);
                })
        })
        closeAction.visible = !isClosed(task)
        item.actions = [cancelAction, closeAction, editAction,]
        return item
    }
    static createEnd(context, task) {
        let title
        switch (task.task_type) {
            case UserTaskType.DISPATCHER:
                title = "Zakończenie obsługi"
                break;
            case UserTaskType.PATROL:
                title = "Patrol skończył pracę "
                break;
            case UserTaskType.SERVICE:
                title = "Serwis zakończył pracę"
                break;
        }
        const setCostAction = TaskItem.paidAction(async () => {
            let token = 0;
            if (task.details.cost) {
                token = task.details.cost.token
            }
            const dialogInstance = await context.$dialog.show(paidCard, {token: token})
            dialogInstance.waitForResult = true
            dialogInstance.wait().then(result => {
                if (result) {
                    TaskService.setCost(context.event.site.id, task.id, result).then(response => {
                        context.addAll([response.data])
                        context.$dialog.notify.info('Koszt został zmieniony')
                    })
                }
            })
        })
        const editAction = TaskItem.editDesriptionAction(async () => {
            const res = await context.$dialog.prompt({
                text: 'Komentarz',
                value: task.details.closing_comment,
                title: title,
                rules: [],
                actions: {
                    false: 'Anuluj',
                    true: {
                        color: 'success',
                        text: 'OK',
                    }
                }
            })
            if (res || res.length == 0) {
                task.details.closing_comment = res
                context.addAll([task])
                TaskService.updateTask(context.event, task.id, task).then(() => {
                    context.$dialog.notify.info('Komentarz został zmieniony ' + res)
                })
            }
        })
        const item = new TaskItem(task.id + "-end", context.event, task);
        item.title = title
        item.color = "red darken-2"
        if (task.details.closing_comment) {
            item.description = task.details.closing_comment
        }
        item.createdAt = task.closed_at
        item.assignees = task.assignees.length == 0 ? [{ user: { username: "Operator" } }] : task.assignees
        item.chips = []
        const cost = task.details.cost
        if (cost != null) {
            const found = this.$store.get('payments/findWithValue', cost.token)
            if (found) {
                item.chips.push({name: found.short, icon: "mdi-currency-usd", color: found.color, textColor:found.textColor})
            } else {
                item.chips.push({ name: cost.cost, icon: "mdi-currency-usd", color: "blue", textColor: "white" })
            }
        }
        item.actions = [editAction, setCostAction]
        return item
    }
    static createStage(context, task, index) {
        const event = context.event
        const stage = task.details.history[index]
        const stageId = task.id + "-" + index
        console.log("stage", index)
        const item = new TaskItem(stageId, event, task)
        let user = task.assignees
        if (stage.user != null) {
            user = [{ user: { username: stage.user } }]
        }
        let editAction = TaskItem.editDesriptionAction(async () => {
            const res = await context.$dialog.prompt({
                text: 'Komentarz',
                value: stage.details,
                title: item.title,
                rules: [],
                actions: {
                    false: 'Anuluj',
                    true: {
                        color: 'success',
                        text: 'OK',
                    }
                }
            })
            if (res || res.length == 0) {
                stage.details = res
                context.addAll([task])
                TaskService.updateTask(context.event, task.id, task).then(() => {
                    context.$dialog.notify.info('Komentarz został zmieniony ' + res)
                })
            }
        })

        item.title = stage.stage
        if (stage.comment) {
            item.title += ' - ' + stage.comment
        }
        item.color = "teal lighten-2"
        item.createdAt = stage.timestamp
        item.description = stage.details
        item.assignees = user
        item.actions = [editAction]
        return item;
    }
    static cancelPatrolAction(click) {
        return {
            text: "Odwołaj patrol",
            icon: "mdi-undo-variant",
            visible: true,
            click: click
        }
    }
    static closeTaskAction(click) {
        return {
            text: "Zamknij zadanie",
            icon: "mdi-close-circle-outline",
            visible: true,
            click: click
        }
    }
    static editDesriptionAction(click) {
        return {
            text: "Edit",
            icon: "mdi-pencil",
            visible: true,
            click: click
        }
    }
    static paidAction(click) {
        return {
            text: "Paid",
            icon: "mdi-currency-usd",
            visible: true,
            click: click
        }
    }
}

export default TaskItem
