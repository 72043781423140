<template>
  <dialog-card title="Opłata za interwencję" :actions="actions" :handler="handlerClick" titleClass="pb-0" ref="card">
    <v-card-text class="pt-0 px-0">
      {{ subtitle }}
    </v-card-text>
    <v-card-text>
      <v-select autofocus v-model="selected" :items="tags" return-object item-value="value" label="Koszt interwencji" clearable chips>
        <template v-slot:selection="{ item }">
          <v-chip :text-color="item.textColor" :color="item.color" small>{{
            item.short
          }}</v-chip>
        </template>
        <template v-slot:item="{ item, attrs, on }">
          <v-list-item v-on="on" v-bind="attrs" #default="{ }">
            <v-list-item-content>
              <v-list-item-title>
                <v-row no-gutters align="center">
                  <span>{{ item.name }}</span>
                  <v-spacer></v-spacer>
                  <v-chip text-color="white" :color="item.color" small>{{
                    item.short
                  }}</v-chip>
                </v-row>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
    </v-card-text>
  </dialog-card>
</template>

<script>
import { get } from "vuex-pathify";

export default {
  name: "PaidCard",
  data: () => ({
    actions: {
      false: 'Anuluj',
      true: {
        color: 'success',
        text: 'OK',
      },
    },
    selected: null,
  }),
  mounted() {
    this.selected = this.token
  },
  props: {
    token: {
      type: Number,
      default: 0
    },
    site: {
      type: Object,
      default: null
    },
  },
  computed: {
    tags: get("payments/items"),
    subtitle() {
      return this.$options.filters.siteFullName(this.site)
    }
  },
  methods: {
    onEnter() {
      this.$refs.card.$refs.actions.trigger(true)
    },
    handlerClick(res, action) {
      if (!action.key) {
        this.$emit('submit', action.key)
      }
      const valid = this.rules ? this.$refs.input.validate() : true
      if (!valid) {
        this.$refs.input.focus()
        return false
      }
      var model = this.selected
      if (!model) {
        model = this.$store.get('payments/empty')
      }
      this.$emit('submit', action.key ? model : action.key)
    },
  },
};
</script>
<style>
.vuedl-layout__closeBtn {
  color: white;
}

.v-application--is-ltr .vuedl-layout__closeBtn {
  right: 14px;
}

.v-application--is-rtl .vuedl-layout__closeBtn {
  left: 14px;
}

.vuedl-layout__closeBtn:hover {
  opacity: 1;
}
</style>
