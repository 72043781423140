import { make } from 'vuex-pathify'
import { dictionaryService } from '@/services/dictionary.service';

const state = {
    value: [],
    modified_at: null
}

const mutations = make.mutations(state)

const empty = {
  value: 0,
  name: '-',
  short: '-',
  color: 'red darken-1',
  textColor: 'white',
}

const getters = {
  // payments : (state) => state.payments,
  modified_at: (state) => state.modified_at,
  items: (state) => state.value,
  // expire: (state) => state.jwt && state.jwt.exp,
  // accessToken: (state) => state.jwt && state.jwt.access,
  findWithValue: (state) => (value) =>  state.value.find((v) => v.value == value),
  empty: () => empty
};

const actions = {
  init: async ({ state, commit, }) => {
    load(state, commit);
    fetchData(state, commit);
  }
};

// function refresh( state, commit, dispatch) {
//   const renewalTimeBuffer = 10000 //* 60 // 1 hour
//   setTimeout(() => {
//     fetchData(state, commit, dispatch)
//   }, renewalTimeBuffer)
// }

async function fetchData(state, commit) {
  const record = await dictionaryService.getPayments()
  if (record.modified_at === state.modified_at) {
    return
  }
  commit('value', record.value);
  commit('modified_at', record.modified_at)
  save(state)
}

function load(state, commit) {
  const old = localStorage.getItem('payments')
  console.log('old', old)
  if (old === null) {
    commit('value', []);
    commit('modified_at', 0);
    return
  }
  const record = JSON.parse(old)
  commit('value', record.value)
  commit('modified_at', record.modified_at)
}

function save(state) {
  const json = JSON.stringify(state)
  localStorage.setItem('payments', json)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
